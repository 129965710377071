import React from 'react'
import * as S from './styles'

function AudioViewer({ audioUrl, item }) {
	if (!audioUrl) {
		return <p>No audio available</p>
	}

	return (
		<S.AudioContainer coverImage={item.cover_id}>
			<audio controls title={item.title} style={{ width: '100%' }}>
				<source src={audioUrl} type={item.file_content_type} />
				Seu navegador não autoriza uso de áudio embedado.
			</audio>
		</S.AudioContainer>
	)
}

export default AudioViewer
