import React from 'react'

import * as S from './styles'

function SpotifyViewer({ spotifyCode, item }) {
	return (
		<S.VideoContainer>
			<iframe
				src={`https://open.spotify.com/embed/episode/${spotifyCode}?utm_source=generator`}
				title={item.title}
				allow="clipboard-write; encrypted-media; fullscreen; picture-in-picture"
				allowFullScreen
				loading="lazy"
				style={{ width: '100%', height: '100%' }}
			></iframe>
		</S.VideoContainer>
	)
}

export default SpotifyViewer
