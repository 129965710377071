import React from 'react'
import {
	BoldItalicUnderlineToggles,
	// CreateLink,
	ListsToggle,
	MDXEditor,
	headingsPlugin,
	linkPlugin,
	listsPlugin,
	markdownShortcutPlugin,
	quotePlugin,
	toolbarPlugin
} from '@mdxeditor/editor'

const RichTextField = ({ name, value, placeholder, onChange, rows = 5 }) => {
	const onMDXEditorChange = (newValue) => {
		onChange({ target: { name, value: newValue } })
	}
	const height = rows * 20
	return (
		<div
			style={{
				border: 'solid',
				borderWidth: 1,
				height,
				overflowY: 'auto',
				width: '100%'
			}}
		>
			<MDXEditor
				markdown={value || ''}
				placeholder={placeholder}
				onChange={onMDXEditorChange}
				className="richText"
				plugins={[
					headingsPlugin(),
					listsPlugin(),
					quotePlugin(),
					markdownShortcutPlugin(),
					linkPlugin(),
					toolbarPlugin({
						toolbarContents: () => (
							<>
								<BoldItalicUnderlineToggles />
								<ListsToggle />
								{/* <CreateLink /> */}
							</>
						)
					})
				]}
			/>
		</div>
	)
}

export default RichTextField
