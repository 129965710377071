import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import CloseIcon from '@mui/icons-material/Close'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/es'

import * as S from './styles'
import '@mdxeditor/editor/style.css'
import AutocompleteEditable from '../../Autocomplete/AutocompleteEditable'
import AutocompleteMultipleFreeSolo from '../../Autocomplete/AutocompleteMultipleFreeSolo'
import { contentDataActions } from '../../../../app/_actions'
import { collectionTypeSlugs } from '../../../../app/_constants'
import { getLanguagesForDropdown } from '../../../../utils/functions'
import cursoTypeList from '../../../../constants/cursoTypes'
import RichTextField from '../../../RichText/RichTextField'

const EditCollectionExtras = ({
	collectionTypeObject,
	inputs,
	onChange,
	messageError,
	messageErrorRef
}) => {
	const dispatch = useDispatch()
	const { t, i18n } = useTranslation()
	const [isFillContentAlertOpened, setIsFillContentAlertOpened] =
		useState(true)

	// @ts-ignore
	const { themes } = useSelector((state) => state.themes)

	const languages = getLanguagesForDropdown()

	useEffect(() => {
		if (!themes || themes.length === 0) {
			dispatch(contentDataActions.getAllThemes())
		}
	}, [dispatch, themes])

	function handleThemesChange({ target }) {
		if (target.value.length < 4) {
			onChange({ target })
		}
	}

	const handleCursoTypeChange = (_, value) => {
		onChange({
			target: {
				name: 'curso_type',
				value
			}
		})
	}

	const handleDateChange = (key, value) => {
		onChange({
			target: {
				name: key,
				value
			}
		})
	}

	return (
		<>
			{isFillContentAlertOpened && (
				<S.FillContentDisclaimer>
					{t(
						'dashboard.fill-content-disclaimer',
						`Facilite e potencialize o acesso a ${
							collectionTypeObject.genre === 'a' ? 'esta' : 'este'
						} ${collectionTypeObject.name.toLowerCase()} preenchendo todos os campos da ficha técnica`
					)}
					<IconButton
						onClick={() => {
							setIsFillContentAlertOpened(false)
						}}
					>
						<CloseIcon sx={{ color: '#fff' }} />
					</IconButton>
				</S.FillContentDisclaimer>
			)}
			{collectionTypeObject.slug === collectionTypeSlugs.CURSO && (
				<>
					<S.InputWrapper>
						<InputLabel>
							{t(
								'dashboard.curso_type',
								'Tipo de curso (obrigatório)'
							)}
						</InputLabel>
						<S.StyledAutocomplete
							disablePortal
							fullWidth
							options={cursoTypeList}
							noOptionsText={t(
								'dashboard.no-options',
								'Não há opções'
							)}
							value={
								inputs['curso_type'].id
									? inputs['curso_type']
									: null
							}
							onChange={handleCursoTypeChange}
							// @ts-ignore
							getOptionLabel={(option) => option.name}
							isOptionEqualToValue={(option) =>
								// @ts-ignore
								option.id === inputs['curso_type'].id
							}
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder={t(
										'dashboard.curso-type-placeholder',
										'Escolha o tipo de curso'
									)}
								/>
							)}
						/>
					</S.InputWrapper>
					<S.InputWrapper>
						<InputLabel>
							{t('dashboard.curso_workload', 'Carga Horária')}
						</InputLabel>
						<S.StyledTextField
							name="curso_workload"
							fullWidth
							value={inputs['curso_workload']}
							onChange={onChange}
							placeholder={t(
								'dashboard.curso_workload-placeholder',
								'Qual a carga horária desse curso?'
							)}
						/>
					</S.InputWrapper>
					<S.InputWrapper>
						<InputLabel>
							{t(
								'dashboard.curso_program',
								'Programação/Ementa do curso'
							)}
						</InputLabel>
						<RichTextField
							name="curso_program"
							value={inputs['curso_program']}
							onChange={onChange}
							placeholder={t(
								'dashboard.curso_program_placeholder',
								'Quais conteúdos e temas são trabalhados nesse curso?'
							)}
							rows={10}
						/>
					</S.InputWrapper>
				</>
			)}
			{collectionTypeObject.slug === collectionTypeSlugs.PROJETO && (
				<>
					<S.InputWrapper>
						<InputLabel>
							{t(
								'dashboard.financier',
								'Financiador(es) do Projeto'
							)}
						</InputLabel>
						<AutocompleteMultipleFreeSolo
							fieldKey="projeto_financers"
							initialValue={inputs['projeto_financers']}
							onChange={onChange}
							placeholder={t(
								'dashboard.financier_placeholder',
								'Use ENTER, vírgula ou ponto e vírgula para adicionar mais de um financiador'
							)}
							isRequired={false}
							messageError={messageError}
						/>
					</S.InputWrapper>
					<S.InputWrapper>
						<InputLabel>
							{t('dashboard.projeto_url', 'Link do Projeto')}
						</InputLabel>
						<S.StyledTextField
							name="projeto_url"
							fullWidth
							value={inputs['projeto_url']}
							onChange={onChange}
							placeholder={t(
								'dashboard.projeto_url_placeholder',
								'Qual o link para esse Projeto?'
							)}
						/>
					</S.InputWrapper>
				</>
			)}
			{[collectionTypeSlugs.CURSO, collectionTypeSlugs.PROJETO].includes(
				collectionTypeObject.slug
			) && (
				<LocalizationProvider
					dateAdapter={AdapterDayjs}
					adapterLocale={i18n.language === 'es-ES' ? 'es' : 'pt-br'}
				>
					<S.DoubleColumnInputs>
						<S.InputWrapper>
							<InputLabel>
								{t('dashboard.date-start', 'Data de Início')}
							</InputLabel>
							<S.StyledDatePicker
								value={inputs['date_start']}
								onChange={(newValue) =>
									handleDateChange('date_start', newValue)
								}
								slotProps={{
									textField: { placeholder: 'DD/MM/AAAA' }
								}}
							/>
						</S.InputWrapper>
						<S.InputWrapper>
							<InputLabel>
								{t(
									'dashboard.date-end',
									'Data de Encerramento'
								)}
							</InputLabel>
							<S.StyledDatePicker
								value={inputs['date_end']}
								slotProps={{
									textField: { placeholder: 'DD/MM/AAAA' }
								}}
								onChange={(newValue) =>
									handleDateChange('date_end', newValue)
								}
							/>
						</S.InputWrapper>
					</S.DoubleColumnInputs>
				</LocalizationProvider>
			)}
			<S.InputWrapper>
				<InputLabel>
					{t('dashboard.keyword', 'Palavras-chave (obrigatório)')}
				</InputLabel>
				<AutocompleteMultipleFreeSolo
					fieldKey="keywords"
					initialValue={inputs['keywords']}
					onChange={onChange}
					placeholder={t(
						'dashboard.keyword-placeholder',
						'Use ENTER, vírgula ou ponto e vírgula para adicionar mais de uma palavra-chave'
					)}
					isRequired={true}
					messageError={messageError}
				/>
			</S.InputWrapper>
			<AutocompleteEditable
				label={t('dashboard.language', 'Idioma')}
				fieldKey="language_id"
				placeholder={t(
					'dashboard.language-placeholder',
					'Adicionar um ou mais idiomas'
				)}
				data={languages}
				onChange={onChange}
				value={inputs.language_id}
			/>
			<AutocompleteEditable
				label={
					t('dashboard.themes', 'Tema(s)') +
					' ' +
					t('dashboard.mandatory', '(obrigatório)')
				}
				fieldKey="themes"
				placeholder={t(
					'dashboard.themes-placeholder',
					'Selecione até três temas que se relacionam com o conteúdo'
				)}
				// loading={fetchingThemes}
				data={themes}
				onChange={handleThemesChange}
				value={inputs.themes}
				error={messageError && !inputs.themes.length ? true : false}
			/>

			<Alert
				sx={{
					width: '100%',
					visibility: messageError ? 'unset' : 'hidden'
				}}
				ref={messageErrorRef}
				severity="error"
			>
				{messageError}
			</Alert>
		</>
	)
}

export default EditCollectionExtras
