import styled from 'styled-components'

export const AudioContainer = styled('div').attrs((props) => ({
	coverImage: props.coverImage
}))`
	margin-top: 16px;
	width: 100%;
	height: 350px;
	background-image: url(${(props) => props.coverImage});
	background-size: cover;
	background-position: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	audio {
		z-index: 1;
		background-color: rgba(255, 255, 255, 0.8);
		border-radius: 4px;
		padding: 4px;
	}
`
