import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'

import * as S from './styles'
import theme from '../../styles/theme'
import noImg from '../../assets/no-image.jpg'

import { useTranslation } from 'react-i18next'
import {
	checkMimeType,
	fetchSpotifyCode,
	fetchYouTubeCode
} from '../../utils/functions'

import Icon from '../../components/Icon'
import ContentUserActions from '../../components/PublicPages/ContentUserActions'
import ContentInfo from './ContentInfo'
import CollectionInfo from './CollectionInfo'
import ContentOrCollectionTypeIcon from '../../components/ContentOrCollectionTypeIcon'
import YouTubeViewer from '../../components/Viewers/YouTubeViewer'
import SpotifyViewer from '../../components/Viewers/SpotifyViewer '
import VideoViewer from '../../components/Viewers/VideoViewer'
import AudioViewer from '../../components/Viewers/AudioViewer'

const ContentOrCollectionDetailDialog = ({
	item,
	handleClose,
	nextItem,
	previousItem,
	hasNext,
	hasPrev,
	open = true,
	children,
	onCollectionContentClick = null
}) => {
	const [videoUrl, setVideoUrl] = useState()
	const [audioUrl, setAudioUrl] = useState()
	const [isSharingBarVisible, setIsSharingBarVisible] = useState(false)
	const { t, i18n } = useTranslation()

	useEffect(() => {
		function keyDownListener(e) {
			if (e.key === 'ArrowLeft') {
				previousItem()
			} else if (e.key === 'ArrowRight') {
				nextItem()
			}
		}
		document.addEventListener('keydown', keyDownListener)

		return () => {
			document.removeEventListener('keydown', keyDownListener)
		}
	}, [nextItem, previousItem])

	useEffect(() => {
		const updateUrls = async () => {
			const fileUrl =
				item.file_id && item.file_content_type
					? process.env.REACT_APP_API.split('api')[0] + item.file_id
					: undefined

			const contentLinkMimetype =
				(await checkMimeType(item.content_link)) || ''

			const newVideoUrl =
				fileUrl &&
				item.file_content_type &&
				item.file_content_type.includes('video') &&
				item.file_content_type !== 'video/x-matroska'
					? fileUrl
					: contentLinkMimetype.includes('video') &&
					  contentLinkMimetype !== 'video/x-matroska'
					? item.content_link
					: undefined
			setVideoUrl(newVideoUrl)

			const newAudioUrl =
				fileUrl &&
				item.file_content_type &&
				item.file_content_type.includes('audio')
					? fileUrl
					: contentLinkMimetype.includes('audio')
					? item.content_link
					: undefined
			setAudioUrl(newAudioUrl)
		}
		updateUrls()
	}, [item])

	if (!item || !item.id) {
		return null
	}

	function moreButtonLink(item) {
		if (i18n.language == 'es-ES') {
			return isCollection
				? `/${item.collection_type.slug}-es/${item.friendly_url}`
				: `/contenido/${item.friendly_url}`
		} else {
			return isCollection
				? `/${item.collection_type.slug}/${item.friendly_url}`
				: `/conteudo/${item.friendly_url}`
		}
	}

	const isCollection = !item.content_type || item.content_type.id === null
	const youTubeCode = fetchYouTubeCode(item)
	const spotifyCode = fetchSpotifyCode(item)

	return (
		<>
			<Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
				<S.ArrowLeft
					onClick={hasPrev ? previousItem : undefined}
					hidden={!hasPrev}
				>
					‹
				</S.ArrowLeft>
				<S.ArrowRight
					onClick={hasNext ? nextItem : undefined}
					hidden={!hasNext}
				>
					›
				</S.ArrowRight>

				{(item.content_type || item.collection_type) && (
					<S.TopWrapper>
						<S.ContentTypeWrapper>
							<ContentOrCollectionTypeIcon
								id={
									isCollection
										? item.collection_type.id
										: item.content_type.id
								}
							/>
							<S.Type>
								{isCollection
									? item.collection_type.name
									: item.content_type.name}
							</S.Type>
						</S.ContentTypeWrapper>
					</S.TopWrapper>
				)}
				<S.IconContainer>
					<Icon type="far fa-times" onClick={handleClose} />
				</S.IconContainer>
				<S.StyledDialogContent>
					{!!youTubeCode ||
					!!spotifyCode ||
					!!videoUrl ||
					!!audioUrl ? (
						<>
							{youTubeCode &&
								!spotifyCode &&
								!videoUrl &&
								!audioUrl && (
									<YouTubeViewer
										videoId={youTubeCode}
										item={item}
									/>
								)}
							{spotifyCode &&
								!youTubeCode &&
								!videoUrl &&
								!audioUrl && (
									<SpotifyViewer
										spotifyCode={spotifyCode}
										item={item}
									/>
								)}
							{!!videoUrl &&
								!youTubeCode &&
								!spotifyCode &&
								!audioUrl && (
									<VideoViewer
										videoUrl={videoUrl}
										item={item}
									/>
								)}
							{!!audioUrl &&
								!youTubeCode &&
								!spotifyCode &&
								!videoUrl && (
									<AudioViewer
										audioUrl={audioUrl}
										item={item}
									/>
								)}
						</>
					) : (
						<S.Cover
							className="cover"
							style={{
								backgroundImage: `url('${
									item.cover_id || noImg
								}')`
							}}
						/>
					)}
					{!isCollection ? (
						<ContentInfo item={item} />
					) : (
						<CollectionInfo
							// handleSelect={setSelectedItem}
							onCollectionContentClick={onCollectionContentClick}
							// selected={selectedItem}
							item={item}
						/>
					)}
				</S.StyledDialogContent>
				<S.StyledDialogActions>
					<S.FileLink>
						<S.GoToContentButton
							target="_blank"
							href={moreButtonLink(item)}
						>
							{t('content-detail.more', 'Ver Mais')}
						</S.GoToContentButton>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								gap: 3
							}}
						>
							<S.SharingButton
								onClick={() =>
									setIsSharingBarVisible(
										(prevState) => !prevState
									)
								}
							>
								<Icon
									type="far fa-regular fa-paper-plane"
									style={{
										color: theme.colors.primary,
										fontSize: '16px'
									}}
								/>
								{t('content-detail.share', 'Compartilhar')}
							</S.SharingButton>

							<ContentUserActions
								id={item.id}
								url={item.friendly_url}
								// handleSelect={setSelectedItem}
								item={item}
								onClose={handleClose}
								isCollection={isCollection}
							/>
						</Box>
					</S.FileLink>
				</S.StyledDialogActions>
				<S.SharingBar className={isSharingBarVisible && 'clicked'}>
					{children}
				</S.SharingBar>
			</Dialog>
		</>
	)
}

export default ContentOrCollectionDetailDialog
