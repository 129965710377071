import React from 'react'

import * as S from './styles'

function VideoViewer({ videoUrl, item }) {
	return (
		<S.VideoContainer>
			<video
				controls
				title={item.title}
				style={{ height: '100%', width: '100%' }}
			>
				<source src={videoUrl} type={item.file_content_type} />
				Seu navegador não autoriza uso de vídeo embedado.
			</video>
		</S.VideoContainer>
	)
}

export default VideoViewer
